@import '../../scss/abstarct/colors';

.cardContainer {
  display: flex;
  flex-direction: row;
  margin-right: 2rem;
  perspective: 200px;
  flex: 0 0 content;

  .card {
    display: flex;
    flex-direction: row;
    min-width: 10rem;
    flex: 1 0 content;
    height: min-content;
    min-height: 5rem;
    padding: 2rem;
    border-color: $color-black;
    border-style: solid dashed solid solid;
    border-width: 1px 2px 1px 1px;

    .discount {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-right: 1rem;
      padding-right: 1rem;
      border-right: 1px solid $color-dark-gray;
      .value {
        font-size: 2.5rem;
        margin: auto;
        font-family: le-monde-livre-std, 'Times New Roman', Times, serif;
      }
      .expiration {
        font-size: 0.8rem;
        min-width: fit-content;
      }
    }
    .description {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .type {
        font-size: 1rem;
        font-weight: 300;
        margin: auto;
        color: $color-dark-gray;
      }
      .comment {
        font-size: 1rem;
        font-weight: 300;
      }
    }
  }
  .useBtn {
    background-color: $color-black;
    display: block;
    color: $color-white;
    border: none;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    padding-left: 0rem;
    padding-right: 0rem;
    transform-origin: 0 100%;
    transform: rotateY(88deg) scaleX(0);
    max-width: 0px;
    transition: all 0.2s ease-in-out;
  }
  .useBtnActivated {
    transform: rotateY(0deg) scaleX(1);
    max-width: 80px;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    &:hover {
      transform: rotate(2deg);
    }
  }
}
