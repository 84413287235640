@import '../../scss/abstarct/colors';

.switch {
  position: relative;
  display: inline-block;
  transition: all 0.3s;
  padding-left: 3rem;
  padding-bottom: 0.5rem;
  &::before {
    content: '';
    position: absolute;
    width: 2.5rem;
    height: 1.4rem;
    border-radius: 7rem;
    background-color: $color-dark-gray;
    top: 0;
    left: 0;
    transition: all 0.3s;
  }
  &::after {
    content: '';
    position: absolute;
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 50%;
    background-color: $color-white;
    top: 0.1rem;
    left: 0.1rem;
    transition: all 0.3s;
  }
}

.checkbox:checked + .switch::after {
  transform: translateX(1.1rem);
}
.checkbox:checked + .switch::before {
  background-color: $color-black;
}
.checkbox {
  display: none;
}
