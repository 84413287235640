@import "../../scss/abstarct/colors";

.title {
  margin: 0px 0px 0.5rem 0px;
}
.visits {
  display: flex;
  flex-direction: column;
  padding: 0px;
  width: 100%;
  .visit {
    display: flex;
    list-style-type: none;
    margin-bottom: 0.5rem;
    .visitService {
      margin-right: auto;
    }
    .visitData {
      margin-left: auto;
    }
  }
}

.emptyMsg {
  color: $color-dark-gray;
  margin-bottom: 2rem;
}
