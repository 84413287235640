.container {
  max-width: 1200px;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
}
.containerFullScreen {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.headerSpace {
  height: 5.5rem;
}

.blackBtn {
  padding: 0.8rem 1rem;
  font-family: le-monde-livre-std, 'Times New Roman', Times, serif;
  font-style: normal;
  font-weight: 300;
  display: inline-block;
  border: none;
  background-color: $color-black;
  color: $color-white;
}

.transparentBlackBtn {
  padding: 0.8rem 1rem;
  font-family: le-monde-livre-std, 'Times New Roman', Times, serif;
  font-style: normal;
  font-weight: 300;
  border: 1px solid $color-black;
  background-color: transparent;
  color: $color-black;
  &:hover,
  &:focus {
    background-color: $color-black;
    color: $color-white;
  }
}

.transparentGreyBtn {
  background-color: transparent;
  color: $color-black;
  margin-right: 1rem;
  border: none;
  &:hover {
    background-color: $color-light-gray;
  }
}

.goldenBtn {
  font-family: le-monde-livre-std, 'Times New Roman', Times, serif;
  font-style: normal;
  font-weight: 300;
  background-color: $color-gloden;
  color: $color-white;
  border: none;
  &:hover {
    background-color: $color-gloden-dark;
  }
}

.greyBtn {
  background-color: $color-light-gray;
  color: $color-black;
  margin-right: 1rem;
  border: none;
  &:hover {
    background-color: $color-light-gray;
  }
}

@media screen and (max-width: 1200px) {
  .container {
    padding: 0px 1.5rem;
  }
  .containerFullScreen {
    padding: 0px 1.5rem;
  }
}

@keyframes fadeinright {
  from {
    transform: translateX(4rem);
    opacity: 0;
  }
  to {
    transform: translateX(0rem);
    opacity: 1;
  }
}

@keyframes fadeinleft {
  from {
    transform: translateX(-4rem);
    opacity: 0;
  }
  to {
    transform: translateX(0rem);
    opacity: 1;
  }
}
