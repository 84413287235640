@import "../../scss/abstarct/colors";

.visitForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 3rem;
  overflow: hidden;
  .label {
    margin-bottom: 1rem;
  }
  .buttons {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 30rem;
    .submitBtn {
      background-color: $color-gloden;
      color: $color-white;
      border: none;
      &:hover {
        background-color: $color-gloden-dark;
      }
    }
    .cancelBtn {
      background-color: transparent;
      color: $color-black;
      margin-right: 1rem;
      border: none;
      &:hover {
        background-color: $color-light-gray;
      }
    }
  }
}
