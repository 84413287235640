@import "../../scss/abstarct/colors";

.customSelect {
  min-height: 2.5rem;
  padding: 0.5rem 0rem 0.5rem 0rem;
  margin-bottom: 1rem;
  width: 100%;
  max-width: 30rem;
  border: 0;
  background-color: $color-light-gray;
  &:focus {
    outline: 1px solid $color-dark-gray;
  }
  option {
    padding: 0.5rem 0rem 0.5rem 0rem;
  }
}
