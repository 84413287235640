@import '../../scss/abstarct/colors';

.searchForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  .searchArea {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 2rem;

    .searchField {
      margin-right: 1rem;
      margin-bottom: 0;
      max-width: unset;
    }

    .mainBtn {
      background-color: $color-gloden;
      color: $color-white;
      border: none;
      &:hover {
        background-color: $color-gloden-dark;
      }
    }
  }
}

.searchContainer {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  width: 100%;
  max-width: 40rem;
  .feedback {
    display: inline-block;
    text-align: center;
  }
}
