@import '../../scss/abstarct/colors';
.container {
  width: 100%;
  max-width: 30rem;
  margin-right: auto;
  margin-left: auto;

  .AddClientForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 3rem;
    .label {
      margin-bottom: 1rem;
    }
    input[type='text'],
    [type='number'],
    [type='email'] {
      min-height: 2.5rem;
      padding: 0px 0.5rem;
      margin-bottom: 1rem;
      width: 100%;
      max-width: 30rem;
      border: none;
      background-color: $color-light-gray;
      &:focus {
        outline: 1px solid $color-dark-gray;
      }
    }

    .nameInput {
      display: grid;
      width: 100%;
      padding: 0px;
      height: fit-content;
      border: none;
      grid-auto-flow: dense;
      grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
      column-gap: 1rem;
      .label {
        grid-column: 1 / -1;
      }
    }
    .mob {
      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      -moz-appearance: textfield;
    }
  }
}
