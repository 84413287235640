@import "../../scss/abstarct/colors";

.switch {
  position: relative;
  display: inline-block;
  overflow: hidden;
  width: 6.5rem;
  height: 2.5rem;

  .slider {
    position: absolute;
    cursor: pointer;
    z-index: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $color-light-gray;

    &:before {
      content: "";
      position: absolute;
      height: 2rem;
      width: 3rem;
      top: 0.25rem;
      bottom: 0.25rem;
      left: 0.25rem;
      background-color: $color-black;
      transition: 0.2s;
    }
  }

  .values {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    .valueOne {
      pointer-events: none;
      text-align: center;
      z-index: 1;
      width: 3rem;
      color: $color-white;
    }
    .valueTwo {
      pointer-events: none;
      text-align: center;
      z-index: 1;
      width: 3rem;
      color: $color-dark-gray;
    }
  }

  input {
    opacity: 0;
    height: 0;
    width: 0;

    &:checked {
      & + .values {
        .slider {
          &:before {
            transform: translateX(3rem);
          }
        }
        .valueOne {
          color: $color-dark-gray;
        }
        .valueTwo {
          color: $color-white;
        }
      }
    }
  }
}
