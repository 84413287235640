@import '../../scss/abstarct/colors';
.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  animation: fadeinright 0.4s ease-out 1 forwards;
  .wideBtn {
    margin-bottom: 1rem;
    min-width: 8rem;
  }
  label {
    margin-bottom: 1rem;
  }
  input[type='number'] {
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
    }
    -moz-appearance: textfield;
    margin-bottom: 1.5rem;
  }
  p {
    margin-top: 0;
  }
  svg {
    margin-left: 0.4rem;
  }
  .buttons {
    margin-top: 1rem;
  }
}

.flip {
  transform: rotateZ(180deg);
}
