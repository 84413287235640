@import '../../scss/abstarct/colors';

.listBS {
  display: flex;
  flex-direction: column;
  font-size: 1vw;
  padding: 0 3em;
  .item {
    display: flex;
    align-items: center;
    padding: 0 3em;
    margin-bottom: 3.6em;
    svg {
      height: 7em;
      margin-right: 3em;
      flex-basis: content;
    }
    .name {
      font-size: 6em;
      margin: 0;
    }
    .deleteIcon {
      display: none;
    }
    .number {
      font-family: le-monde-livre-std, 'Times New Roman', Times, serif;
      font-weight: 700;
      font-size: 9em;
      color: $color-dark-gray;
      margin-left: auto;
    }
  }
}

.list {
  flex-basis: 50%;
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  .item {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
    svg {
      height: 2rem;
      margin-right: 1rem;
      flex-basis: content;
    }
    .name {
      margin: 0;
    }
    .deleteIcon {
      margin-left: auto;
      margin-right: 1rem;
      background-color: transparent;
      cursor: pointer;
      svg {
        margin: auto 0;
      }
    }
    .number {
      font-family: le-monde-livre-std, 'Times New Roman', Times, serif;
      font-weight: 700;
      font-size: 2rem;
      color: $color-dark-gray;
    }
  }
}
