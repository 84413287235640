@import '../../scss/abstarct/colors';

.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: 1vw;
  .atService {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 35%;
    flex-basis: 35%;
    background-color: $color-black;
    .icon {
      height: 10em;
      flex-basis: content;
      flex-grow: 0;
      flex-shrink: 0;
      padding-right: 5em;
      border-right: 2px dashed $color-normal-gray;
    }
    .waitingList {
      flex-basis: content;
      flex-grow: 1;
      display: flex;
      max-width: 35em;
      flex-direction: column;
      align-items: center;
      color: $color-white;
      margin-left: 5em;
      .title {
        font-size: 2.8em;
        margin-bottom: 1em;
      }
      .name {
        font-size: 6em;
        margin-top: 0;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
