@import '../../scss/abstarct/colors';

.atService {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  min-height: 8rem;
  .icon {
    height: 4rem;
    padding: 0.5rem;
    flex-basis: content;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 3rem;
    border-right: 2px dashed $color-normal-gray;
  }
  .serviceList {
    flex-basis: content;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 3rem;
    .title {
      margin-bottom: 1rem;
    }
  }
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .atService {
    .serviceList {
      flex-basis: 100%;
      margin: 0 auto 1rem 0;
    }
    .icon {
      flex-basis: 100%;
      margin: 1rem auto 1rem 0;
      height: 4rem;
      padding: 0.5rem;
      flex-shrink: 1;
      border-right: none;
    }
    .nextBtn {
      margin: 0 auto;
      flex-basis: 90%;
    }
  }
}
