@import '../../scss/abstarct/colors';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $color-black;
  color: $color-white;
  h1 {
    color: $color-gloden;
    margin-bottom: 0;
  }
  .imageContainer {
    display: flex;
    .clientsNum {
      font-family: le-monde-livre-std, 'Times New Roman', Times, serif;
      font-weight: 700;
      font-size: 1.7rem;
      color: $color-gloden;
      align-self: flex-start;
    }
  }
  .info {
    max-width: 12rem;
    text-align: center;
    color: inherit;
    font-family: le-monde-livre-std, 'Times New Roman', Times, serif;
    line-height: 1.5rem;
    border-top: 2px dashed $color-dark-gray;
    padding-top: 1rem;
    margin-top: 1rem;
  }
}
