@import '../../scss/abstarct/colors';

.name {
  margin-top: 0;
  &:last-child {
    margin-bottom: 0;
  }
}

.nameBtn {
  background-color: transparent;
  font-size: 1.2rem;
  font-family: le-monde-livre-std, 'Times New Roman', Times, serif;
  font-weight: 700;
  margin-top: 0;
  color: $color-black;

  &:hover {
    svg {
      transform: translateX(0.2rem);
      transition: all 0.2s ease-in-out;
    }
  }
  &:last-child {
    margin-bottom: 0;
  }
  svg {
    margin-left: 0.4rem;
  }
}
