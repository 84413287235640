@import "../../scss/abstarct/colors";
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.container {
  display: inline-block;
  position: relative;
  .spinner {
    position: absolute;
    top: 10%;
    left: 10%;
    width: 80%;
    height: 80%;
    border-radius: 50%;
    border-top: 2px solid $color-gloden;
    animation: spinner 0.6s linear infinite;
  }
}
