@import '../../scss/abstarct/colors';

.info {
  display: inline-flex;
  align-items: center;
  margin-right: 0.5rem;
  min-height: 2rem;
  .lable {
    font-size: 1rem;
    margin-right: 0.8rem;
    color: $color-dark-gray;
  }
  .text {
    font-family: 'Roboto', sans-serif;
  }
  .bigText {
    font-family: le-monde-livre-std, 'Times New Roman', Times, serif;
    font-weight: 700;
    font-size: 2rem;
    color: $color-black;
  }
  .editBtn,
  .okBtn,
  .cancelBtn {
    padding: 0px;
    margin-left: 0.5rem;
    border-style: none;
    background-color: transparent;
    img {
      pointer-events: none;
    }
  }
  .editBtn {
    visibility: hidden;
  }

  &:hover {
    .editBtn {
      visibility: unset;
      opacity: 0.8;
      &:hover {
        opacity: 1;
      }
    }
  }

  input[type='text'] {
    min-height: 2rem;
    padding: 0px 0.5rem;
    width: 100%;
    max-width: 30rem;
    border: none;

    background-color: $color-light-gray;
    &:focus {
      outline: 1px solid $color-dark-gray;
    }
  }
}

.hidden {
  display: none;
}
