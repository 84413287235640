@import "../../scss/abstarct/colors";

.signInForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  max-width: 350px;
  .label {
    margin-bottom: 1rem;
  }
  input[type="text"],
  input[type="password"] {
    min-height: 2.5rem;
    padding: 0px 0.5rem;
    margin-bottom: 1rem;
    width: 100%;
    max-width: 30rem;
    border: none;
    background-color: $color-light-gray;
    &:focus {
      outline: 1px solid $color-dark-gray;
    }
  }
}
