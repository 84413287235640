@import '../../scss/abstarct/colors';

.pageDivider {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  .queue {
    flex-basis: 35%;
    flex-grow: 1;
    min-width: fit-content;
  }
  .regContainer {
    flex-basis: 65%;
    flex-grow: 1;
    min-width: max-content;
    padding:0 4rem 0 4rem
  }
}

@media only screen and (max-width: 768px) {
  .pageDivider {
    .regContainer {
      padding: 0rem 1rem;
    }
  }

}
