@import "../../scss/abstarct/colors";

.header {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 3rem;
  margin-bottom: 2rem;

  .clientAvatar {
    margin-right: 2rem;
  }

  .name {
    margin-top: 0px;
    margin-bottom: 0.8rem;
  }

  span {
    font-family: "Roboto", sans-serif;
    color: $color-dark-gray;
  }
}
.inlineInfo {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  margin-bottom: 2rem;
  .infoLable {
    font-size: 1rem;
    margin-right: 0.8rem;
    color: $color-dark-gray;
  }
}
