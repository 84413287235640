@import '../../scss/abstarct/colors';

.bar {
  width: 100%;
  height: 5rem;
  background-color: $color-black;
  position: absolute;
  z-index: 1;
  .container {
    max-width: 1200px;
    height: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    flex-direction: row;
    .logoContainer {
      display: flex;
      .logo {
        height: 3rem;
        width: 3rem;
        margin-right: 2rem;
      }
      .menuBtn {
        display: none;
        background-color: transparent;
        border: none;
      }
    }
    .menu {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      padding: 0px;
      margin: 0px;
      list-style-type: none;
      color: $color-white;
      justify-content: flex-start;
      transition: 0.4s all ease-in-out;
      .clientInfo {
        margin-left: auto;
      }
      .menuItem {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 2rem;
        a,
        button {
          color: inherit;
          opacity: 0.8;
        }
        .selected {
          opacity: 1;
        }
        button {
          padding: 0px;
          font-family: 'Roboto', sans-serif;
          border: none;
          cursor: pointer;
          background-color: transparent;
          color: inherit;
          white-space: nowrap;
        }
        .clientName {
          font-weight: bold;
          border-right: 2px solid $color-dark-gray;
          margin-right: 0.5em;
          padding-right: 0.5em;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .bar {
    width: 100%;
    background-color: unset;
    .container {
      flex-direction: column;
      max-width: 100vw;
      overflow-x: hidden;
      .logoContainer {
        width: 100%;
        height: 5rem;
        padding: 1rem 1rem;
        background-color: $color-black;
        justify-content: space-between;
        .logo {
          height: 3rem;
          width: 3rem;
        }
        .menuBtn {
          display: unset;
        }
      }
      .menu {
        flex-direction: column;
        flex-grow: unset;
        top: 5rem;
        position: absolute;
        visibility: visible;
        opacity: 1;
        width: 100%;
        height: calc(100vh - 5rem);
        background-color: $color-black;
        .clientInfo {
          margin: unset;
        }
        .menuItem {
          margin: 0.7rem auto;
        }
      }
      .closed {
        visibility: hidden;
        pointer-events: none;
        opacity: 0;
      }
    }
  }
}
