@import "../../scss/abstarct/colors";

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 3rem;
  overflow: hidden;
  //transition: 0.2s all cubic-bezier(0.39, 0.575, 0.565, 1);

  .label {
    margin-bottom: 1rem;
  }

  input[type="text"],
  textarea {
    min-height: 2.5rem;
    padding: 0px 0.5rem;
    margin-bottom: 1rem;
    width: 100%;
    max-width: 30rem;
    border: none;

    background-color: $color-light-gray;
    &:focus {
      outline: 1px solid $color-dark-gray;
    }
  }

  input[type="text"],
  textarea {
    min-height: 2.5rem;
    margin-bottom: 1rem;
    width: 100%;
    max-width: 30rem;
    border: none;
    background-color: $color-light-gray;

    &:focus {
      outline: 1px solid $color-dark-gray;
    }
  }

  textarea {
    padding: 0.5rem;
  }

  input[type="text"] {
    padding: 0px 0.5rem;
  }

  input[type="date"] {
    min-height: 2.5rem;
    padding: 0px 0.5rem;
    margin-bottom: 1rem;
    width: 100%;
    max-width: 30rem;
    border: none;
    background-color: $color-light-gray;
    &:focus {
      outline: 1px solid $color-dark-gray;
    }
  }
}
