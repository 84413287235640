@import '../../scss/abstarct/colors';

.container {
  position: relative;
  display: flex;
  flex: 1 0 17rem;
  max-width: 22rem;
  flex-direction: column;
  justify-content: flex-start;
  width: 16rem;
  margin-right: 2rem;
  .controller {
    display: flex;
    align-content: stretch;
    align-items: stretch;
    background-color: $color-light-gray;
    .btn {
      flex: 1 1 auto;
      background-color: $color-black;
      border: none;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      position: relative;
      &:not(:last-child)::after {
        content: '';
        position: absolute;
        width: 1px;
        height: 50%;
        right: 0;
        background-color: $color-dark-gray;
      }
      &::before {
        content: '';
        width: 4rem;
        height: 4rem;
        transform: scale(1.5);
        position: absolute;
        top: calc(50% - 2rem);
        right: calc(50% - 2rem);
        background-color: $color-light-gray;
        opacity: 0;
        border-radius: 50%;
        transition: all 0.5s;
      }
      &:active {
        &::before {
          transform: scale(0);
          opacity: 0.4;
          transition: all 0s;
        }
      }
      &:focus,
      &:hover {
        background-color: $color-black;
        outline: none;
        svg {
          color: $color-white;
        }
      }
    }
  }
  .imageContainer {
    min-height: 11rem;
    background-color: $color-light-gray;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    overflow: hidden;
    .exp {
      position: absolute;
      top: 0px;
      right: 0px;
      padding: 0.3rem;
      font-size: 0.8rem;
      z-index: 1;
      color: $color-white;
      background-color: rgba($color: $color-black, $alpha: 0.5);
    }
    &::before {
      content: '';
      height: 1.5rem;
      width: 1.5rem;
      border-radius: 50%;
      position: absolute;
      top: calc(50% - 0.75rem);
      left: 0rem;
      transform: translateX(-0.75rem);
      background-color: $color-white;
    }
    &::after {
      content: '';
      height: 1.5rem;
      width: 1.5rem;
      border-radius: 50%;
      position: absolute;
      top: calc(50% - 0.75rem);
      right: 0rem;
      transform: translateX(0.75rem);
      background-color: $color-white;
    }
  }
  .content {
    min-height: 7rem;
    padding: 1rem;
    flex-grow: 1;
    background-color: $color-light-gray;
    .title {
      margin-top: 0px;
      color: $color-gloden;
    }
  }
}

.inactive > *:not(.controller) {
  opacity: 0.5;
}
