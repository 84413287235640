@import "../../scss/abstarct/colors";

.menuContainer {
  position: relative;
  display: inline-block;
  margin-left: auto;

  .dotsBtn {
    background-color: transparent;
    border: none;
    margin-left: auto;
    &:hover {
      opacity: 0.8;
      cursor: pointer;
    }
  }

  .menu {
    min-width: 10rem;
    position: absolute;
    display: flex;
    flex-direction: column;
    background-color: $color-white;
    box-shadow: 8px 8px 19px #e8e8e8, -8px -8px 19px #ffffff;
    transform: translate(-100%, -20%);
    z-index: 1;
    font-family: inherit;

    .menuItem {
      display: block;
      border: none;
      font-family: inherit;
      padding: 1rem 1rem;
      text-align: left;
      color: $color-black;
      background-color: transparent;
      cursor: pointer;
      &:hover {
        background-color: $color-light-gray;
      }
      &:focus {
        background-color: $color-light-gray;
      }
    }
  }
}
