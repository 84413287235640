html {
  box-sizing: border-box;
}
* {
  box-sizing: inherit;
  &:before {
    box-sizing: inherit;
  }
  &:after {
    box-sizing: inherit;
  }
}
