@import "../../scss/abstarct/colors";

.pickerLable {
  display: inline-flex;
  position: relative;
  min-height: 2.5rem;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  align-items: center;
  justify-content: center;
  width: fit-content;
  background-color: $color-light-gray;
  color: $color-black;
  border: none;
  &:hover {
    color: $color-white;
    background-color: $color-black;
  }
  &:focus-within {
    border: 1px solid $color-dark-gray;
  }
  .filePick {
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
    top: 0px;
    left: 0px;
  }
}

.progressBar {
  width: 100%;
  max-width: 8rem;
  height: 2.5rem;
  margin-bottom: 1rem;
  position: relative;
  background-color: $color-light-gray;
  .progress {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    color: $color-white;
    background: repeating-linear-gradient(
      45deg,
      $color-gloden-dark,
      $color-gloden-dark 10px,
      $color-gloden 10px,
      $color-gloden 20px
    );
    transition: all 200ms ease-in-out;
  }
}

.uploadGroup {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  img {
    height: 2rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
}
