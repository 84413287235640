@import "../../scss/abstarct/colors";

.message {
  position: fixed;
  display: flex;
  align-items: center;
  bottom: 1rem;
  right: 2rem;
  font-size: 0.9rem;
  padding: 1rem 1rem 1rem 2.2rem;
  color: $color-light-gray;
  background-color: $color-black;
  margin-bottom: 1rem;
  pointer-events: none;
  z-index: 1;
  animation: fadeinout 4s ease-in-out 1 normal forwards;
}
.attention {
  background: $color-black url(./images/attentionIcon.svg) top 50% left 0.8rem
    no-repeat;
}
.sucsess {
  background: $color-black url(./images/checkIcon.svg) top 50% left 0.8rem
    no-repeat;
}
.error {
  background: $color-black url(./images/errorIcon.svg) top 50% left 0.8rem
    no-repeat;
}

@keyframes fadeinout {
  0% {
    transform: translateY(2rem);
    opacity: 0;
  }
  10% {
    transform: translateY(0);
    opacity: 1;
  }
  90% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(2rem);
    opacity: 0;
  }
}
