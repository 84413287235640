@import '../../scss/abstarct/colors';

.counters {
  flex-basis: auto;
  flex-grow: 1;
  align-self: flex-start;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 1rem;
  margin: 1rem;
  background-color: $color-light-gray;
  .counter {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 1rem;
    .number {
      margin-top: 0;
      margin-bottom: 0.5rem;
      font-size: 2rem;
      color: $color-dark-gray;
    }
    .title {
      font-size: 0.8rem;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}
