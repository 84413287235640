@import "../../scss/abstarct/colors";

.results {
  display: flex;
  flex-direction: column;
  padding: 0px;
  width: 100%;
  li {
    display: flex;
    flex-direction: row;
    list-style-type: none;
    padding: 1rem;
    margin-bottom: 0.5rem;
    &:hover {
      background-color: $color-light-gray;
    }
    a {
      color: inherit;
    }
    .clientInfo {
      display: flex;
      margin-left: auto;
      color: $color-dark-gray;
    }
  }
}
