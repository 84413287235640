@import '../../scss/abstarct/colors';

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 3rem;
  overflow: hidden;
  //transition: 0.2s all cubic-bezier(0.39, 0.575, 0.565, 1);

  .valueInput {
    width: 100%;
    max-width: 30rem;
    height: fit-content;
    display: flex;
    border: none;
    padding: 0;
    margin: 0rem 0rem 1rem 0rem;
    &:active,
    &:focus-within {
      outline: 1px solid $color-dark-gray;
    }
    input[type='number'] {
      width: calc(100% - 6.5rem);
      height: 2.5rem;
      padding: 0px 0.5rem;
      margin-bottom: unset;
      border: none;
      background-color: $color-light-gray;
      &:focus {
        outline: none;
      }
      -moz-appearance: textfield;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }
  .label {
    margin-bottom: 1rem;
  }
  input[type='text'] {
    margin-bottom: 1rem;
  }
  input[type='date'] {
    min-height: 2.5rem;
    padding: 0px 0.5rem;
    margin-bottom: 1rem;
    width: 100%;
    max-width: 30rem;
    border: none;
    background-color: $color-light-gray;
    &:focus {
      outline: 1px solid $color-dark-gray;
    }
  }
  .buttons {
    display: flex;
    flex-direction: row;
  }
}
.closed {
  max-height: 500px;
  margin-bottom: 0px;
}
