@import "../../scss/abstarct/colors";

.title {
  margin: 0px 0px 0.5rem 0px;
}
.discounts {
  display: flex;
  flex-direction: row;
  padding-bottom: 1rem;
  padding-left: 0px;
  width: 100%;
  overflow-x: scroll;
  margin-bottom: 2rem;

  &:hover {
    &::-webkit-scrollbar-track {
      background-color: $color-white;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $color-normal-gray;
    }
  }

  &::-webkit-scrollbar {
    height: 5px;
  }
  &::-webkit-scrollbar-track {
    background-color: $color-white;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $color-white;
    &:hover {
      background: $color-black;
    }
  }
}

.emptyMsg {
  color: $color-dark-gray;
  margin-bottom: 2rem;
}
